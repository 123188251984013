export const basicInformationSubmitCallback =
  (clientId, handlePatchClient, savePreferenceContactInfo, allowContact) =>
  (values) =>
    new Promise((resolve, reject) => {
      // Patch Client: Save First Name, Last Name, and Nickname
      if (values?.first_name === undefined) {
        values.first_name = ''
      }
      if (values?.last_name === undefined) {
        values.last_name = ''
      }
      if (values?.nick_name === undefined) {
        values.nick_name = ''
      }
      handlePatchClient(values)
        .then(() => {
          // Mutation: Save preferredLanguage
          savePreferenceContactInfo({
            variables: {
              clientId,
              preferences: {
                allowContact: allowContact ?? true,
                preferredLanguage: values.preferred_language,
              },
            },
          })
            .then(() => resolve())
            .catch(() => reject())
        })
        .catch(() => reject())
    })

export const alternateContactInformationSubmitCallback =
  (
    clientId,
    saveAlternateContactInformation,
    savePreferenceContactInfo,
    preferredLanguage
  ) =>
  (values) => {
    if (values?.alternate_email === undefined) {
      values.alternate_email = ''
    }
    if (values?.alternate_phone === undefined) {
      values.alternate_phone = ''
    }
    savePreferenceContactInfo({
      variables: {
        clientId,
        preferences: {
          allowContact: values['allow_contact'] === 'true',
          preferredLanguage: preferredLanguage,
        },
      },
    })
    delete values['allow_contact']
    return Promise.all(
      Object.keys(values).map((key) => {
        return Promise.resolve(
          saveAlternateContactInformation({
            variables: {
              clientId,
              alternateContact: values[key] === '' ? null : values[key],
              alternateContactType: key.toLocaleUpperCase(),
            },
          })
        )
      })
    )
  }
