import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { StyledParagraph } from 'Components/Screener/style'
import CalendarDays from 'Components/Shared/CalendarDays/CalendarDays'
import CalendarMonths from 'Components/Shared/CalendarMonths/CalendarMonths'
import CalendarYears from 'Components/Shared/CalendarYears/CalendarYears'
import { change } from 'redux-form' // Import the change action
import { Col, Row } from 'styled-bootstrap-grid'

import FieldSelect from './FieldSelect'

const DateTypeField = ({ name, validate, label, children }) => {
  const normalize = (value) => +value

  return (
    <Col md='3.5'>
      <FieldSelect
        name={name}
        label={label}
        validate={validate}
        normalize={normalize}
      >
        {children}
      </FieldSelect>
    </Col>
  )
}

const MilitaryDateSubSelector = ({
  meta: { error, form },
  input: { name },
  namePrefix,
  text,
  startDay,
  startMonth,
  startYear,
  endDay,
  endMonth,
  endYear,
}) => {
  let [daysInMonth, setDaysInMonth] = useState(CalendarDays)
  const dispatch = useDispatch()
  const validateMonth = React.useCallback(
    (value) => (!value ? 'Enter a month' : error?.[`${namePrefix}Month`]),
    [error, namePrefix]
  )
  const validateDay = React.useCallback(
    (value) => (!value ? 'Enter a day' : error?.[`${namePrefix}Day`]),
    [error, namePrefix]
  )
  const validateYear = React.useCallback(
    (value) => (!value ? 'Enter a year' : error?.[`${namePrefix}Year`]),
    [error, namePrefix]
  )
  //validate is already made just make value empty
  useEffect(() => {
    setDaysInMonth(
      getDaysInMonth(
        namePrefix === 'start' ? startMonth : endMonth,
        namePrefix === 'start' ? startYear : endYear
      )
    )
    if (startDay > daysInMonth && namePrefix === 'start') {
      dispatch(change(form, `household.military_time_served[0].start_day`, 0)) // Reset startDay in Redux Form
    }
    if (endDay > daysInMonth && namePrefix === 'end') {
      dispatch(change(form, `household.military_time_served[0].end_day`, 0)) // Reset startDay in Redux Form
    }
  }, [
    startMonth,
    startYear,
    startDay,
    endMonth,
    endYear,
    namePrefix,
    daysInMonth,
  ])

  function getDaysInMonth(month, year) {
    let currentMonth, currentYear
    currentMonth = month == null ? 1 : month
    currentYear = year == null ? new Date().getFullYear() : year

    return new Date(currentYear, currentMonth, 0).getDate()
  }
  const getDayOptions = () => {
    return CalendarDays(daysInMonth)
  }

  return (
    <Row>
      <Col md='1.5' alignSelf='center'>
        <StyledParagraph marginBottom='0'>{text}</StyledParagraph>
      </Col>
      <DateTypeField
        name={`${name}.${namePrefix}_month`}
        validate={validateMonth}
        label='Month'
      >
        {CalendarMonths()}
      </DateTypeField>
      <DateTypeField
        name={`${name}.${namePrefix}_day`}
        validate={validateDay}
        label='Day'
      >
        {getDayOptions()}
      </DateTypeField>
      <DateTypeField
        name={`${name}.${namePrefix}_year`}
        validate={validateYear}
        label='Year'
      >
        {CalendarYears()}
      </DateTypeField>
    </Row>
  )
}

export default MilitaryDateSubSelector
