import { CSSProperties } from '@material-ui/core/styles/withStyles'
import * as u from 'Shared/Theme/utilities.styles'
import styled from 'styled-components'

export const StyledCategoryFilterItem = styled.div<CSSProperties>`
  width: ${(props) => (props.width ? props.width : '100%')};
  ${u.dFlex};
  ${u.flexRow};
  ${u.alignItemsCenter};
  justify-content: flex-start;
  margin: ${(props) => props.margin};
  padding: ${(props) => props.padding};
`

export const StyledCategoryFilterItemLabel = styled.label`
  font-size: 1.05rem !important;
  font-family:
    Noto Sans HK,
    sans-serif,
    'sans-serif';
  font-weight: 500;
  line-height: 1.5;
`

StyledCategoryFilterItem.displayName = 'StyledCategoryFilterItem'
StyledCategoryFilterItemLabel.displayName = 'StyledCategoryFilterItemLabel'
