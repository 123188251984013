import React, { ChangeEvent, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import { useNavigate } from 'react-router-dom'
import {
  ClientDataDocument,
  GetClientSummaryDocument,
  SavePreexistingBenefitsDocument,
} from '__generated__/graphql'
import { useMutation, useQuery } from '@apollo/client'
import { Menu } from '@material-ui/core'
import { StyledFormControlLabel } from 'Components/CaseManagement/ClientRecords/FilterColumn/style'
import CaseCategoryHeading from 'Components/CaseManagement/ClientSummary/Cases/CaseCategoryHeading'
import { parseServices } from 'Components/CaseManagement/helpers'
import { BenefitStyledCheckbox } from 'Components/CaseManagement/Modals/style'
import { StyledDynamicCtaButton } from 'Components/Screener/style'
import { StyledButtonSelect } from 'Components/Shared/Dropdown/Styles'
import HeaderWithContainerBenefitCheckList from 'Components/Shared/HeaderWithContainerAndCard/HeaderWithContainerBenefitCheckList'
import LoadingIndicator from 'Components/Shared/LoadingIndicator/LoadingIndicator'
import { Button } from 'Components/Shared/Uielements/styles/buttons.style'
import PageNotFound from 'Pages/PageNotFound/PageNotFound'
import { MOBILE_MEDIA_QUERY } from 'Shared/constants'
import { isFeatureToggleOn } from 'Shared/helpers'
import { RootState } from 'Store'
import { Container } from 'styled-bootstrap-grid'

import {
  Benefitcontainer,
  Benefitsubcategories,
  BtnBenefitWrapper,
  Heading,
  StyledBenefitMenuItem,
  StyledBenefitScrollerBox,
  StyledHeaderBenefit,
  StyledHeaderContainer,
  StyledScrollerBoxContent,
  Text,
} from './style'

const BenefitsRecieved = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [dropdownWidth, setDropdownWidth] = React.useState('auto')
  const isMobile = useMediaQuery({
    query: MOBILE_MEDIA_QUERY,
  })
  const navigate = useNavigate()
  const { data } = useQuery(ClientDataDocument)
  const clientLocationId = useSelector(
    (state: RootState) => state.client.clientLocationId
  )
  const { data: assistedScreeningClientData, loading } = useQuery(
    GetClientSummaryDocument,
    {
      variables: {
        id: clientLocationId || sessionStorage.getItem('clientLocationId'),
      },
    }
  )

  const handleResize = () => {
    setDropdownWidth(
      `${document.querySelector('#basic-button')?.getBoundingClientRect()?.width}px`
    )
  }
  useEffect(() => {
    window.addEventListener('resize', handleResize, false)
  }, [])
  const [savePreexistingBenefits] = useMutation(SavePreexistingBenefitsDocument)
  const clientData =
    data?.me?.userable?.__typename === 'Client'
      ? data?.me?.userable
      : assistedScreeningClientData?.clientLocation?.client

  const [dropdownValue, setDropdownValue] = React.useState('')
  const [selected, setSelected] = React.useState('')
  const [serviceIds, setServiceIds] = React.useState<string[]>([])

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    handleResize()
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleDropdownSelect = (value: string) => {
    setDropdownValue(value)
    handleClose()
  }
  const handleDropdownSubmit = () => {
    if (dropdownValue === 'yes') {
      setSelected('yes')
    } else {
      navigate('/eligibility')
    }
  }

  if (loading) {
    return <LoadingIndicator show={true} />
  }

  if (!isFeatureToggleOn('already_receiving_benefits')) {
    if (clientData) {
      navigate('/screening')
    }
    return <PageNotFound />
  }

  if (!data || !clientData) {
    return null
  }

  const screeningId = clientData.screening?.id

  const parsedScreenableServices = parseServices({
    services: clientData.screenableServices,
  })

  const handleSubmit = () => {
    if (screeningId && serviceIds) {
      savePreexistingBenefits({
        variables: {
          screeningId,
          serviceIds,
        },
      })
        .then(() => {
          navigate('/eligibility')
        })
        .catch((error) => {
          console.log('savePreexistingBenefits error: ', error)
        })
    }
  }

  const open = Boolean(anchorEl)
  const handleCheckBoxChange = (
    event: ChangeEvent<HTMLInputElement>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    option: any
  ) => {
    if (event.target.checked) {
      setServiceIds([...serviceIds, event?.target.value])
    } else {
      setServiceIds(serviceIds.filter((b) => b !== event?.target.value))
    }
  }

  return (
    <HeaderWithContainerBenefitCheckList padding={0}>
      <Container>
        <StyledHeaderBenefit>
          <StyledHeaderContainer>
            <Heading>
              Are you currently receiving funds for or participating in any
              state or federal benefit programs?{' '}
            </Heading>
            <Text marginBottom={selected ? 'auto' : '50px'}>
              This information will help us best serve you and the programs you
              are eligible for.
            </Text>
          </StyledHeaderContainer>
        </StyledHeaderBenefit>
        {!selected && (
          <>
            <StyledButtonSelect
              id='basic-button'
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup='true'
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}
              variant='outlined'
              className='standard'
              style={{ padding: '18.5px 14px', fontSize: '1rem' }}
            >
              {dropdownValue || 'Select an option'}
            </StyledButtonSelect>
            <Menu
              id='basic-menu'
              aria-labelledby='basic-button'
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              style={{ width: dropdownWidth }}
            >
              <StyledBenefitMenuItem
                onClick={() => handleDropdownSelect('yes')}
                style={{ width: '100%' }}
              >
                Yes
              </StyledBenefitMenuItem>
              <StyledBenefitMenuItem onClick={() => handleDropdownSelect('no')}>
                No
              </StyledBenefitMenuItem>
              <StyledBenefitMenuItem
                onClick={() =>
                  handleDropdownSelect("I don't know/Prefer not to answer")
                }
              >
                I don't know/Prefer not to answer
              </StyledBenefitMenuItem>
            </Menu>
            <style>{` .MuiMenu-paper { width: ${dropdownWidth}; } `}</style>
            <StyledDynamicCtaButton
              onClick={handleDropdownSubmit}
              style={{ marginTop: '1rem' }}
            >
              Submit
            </StyledDynamicCtaButton>
          </>
        )}
        {selected === 'yes' && (
          <>
            <Text>Please check all that apply</Text>
            <StyledBenefitScrollerBox>
              <StyledScrollerBoxContent>
                {Object.keys(parsedScreenableServices).map((key) => {
                  return (
                    <Benefitcontainer key={key}>
                      <CaseCategoryHeading
                        openEligibilityKey={key}
                        $fontSize='20px'
                        $iconMargin='0 20px 0 0'
                      />
                      {parsedScreenableServices[key].map((option, i) => {
                        const isChecked = serviceIds.includes(option.id)
                        return (
                          <Benefitsubcategories
                            key={option.id}
                            isChecked={isChecked}
                          >
                            <StyledFormControlLabel
                              style={{ marginBottom: '0' }}
                              control={
                                <BenefitStyledCheckbox
                                  data-testid={`checkbox_${option.id}`}
                                  checked={isChecked}
                                  onChange={(event) =>
                                    handleCheckBoxChange(event, option)
                                  }
                                  value={option.id}
                                />
                              }
                              label={option.name}
                            />
                          </Benefitsubcategories>
                        )
                      })}
                    </Benefitcontainer>
                  )
                })}
              </StyledScrollerBoxContent>
            </StyledBenefitScrollerBox>
            <BtnBenefitWrapper>
              {serviceIds.length > 0 && (
                <Button
                  primary
                  margin='1rem 0 0 0'
                  onClick={() => setServiceIds([])}
                  radius='56px'
                  padding='10px 16px 10px 16px'
                  width='164px'
                  style={{
                    background: 'rgba(255, 255, 255, 1)',
                    border: '1px solid #EFEFEF',
                    color: 'rgba(21, 21, 21, 1)',
                    textShadow: 'none',
                    marginTop: isMobile && '2rem',
                  }}
                >
                  Clear All
                </Button>
              )}
              <Button
                primary
                margin='1rem 0 0 0'
                onClick={() => handleSubmit()}
                radius='56px'
                padding='10px 16px 10px 16px'
                width='164px'
                disabled={serviceIds.length === 0}
                style={{
                  backgroundColor:
                    serviceIds.length === 0 ? 'hsla(0, 0%, 78%, 1)' : '',
                  width: isMobile && !serviceIds.length && '100%',
                  marginTop: isMobile && '2rem',
                }}
              >
                Submit
              </Button>
            </BtnBenefitWrapper>
          </>
        )}
      </Container>
    </HeaderWithContainerBenefitCheckList>
  )
}

export default BenefitsRecieved
