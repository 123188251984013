import React from 'react'
import { useMediaQuery } from 'react-responsive'
import {
  ReverseCol,
  StyledCallsToActionParent,
  StyledParentButtonsActionsInMobile,
} from 'Components/Screener/style'
import {
  DESKTOP_MEDIA_QUERY,
  FIRST_STEP,
  MOBILE_MEDIA_QUERY,
} from 'Shared/constants'
import { Row } from 'styled-bootstrap-grid'

import CallsToAction from './CallsToAction'
import ScreenerBackButton from './ScreenerBackButton'

const ScreenerActions = (props) => {
  const {
    smallerWidth,
    tabletMargin,
    currentStep: stepNumber,
    screenerCompleted,
    invalid,
  } = props
  const isDesktop = useMediaQuery({
    query: DESKTOP_MEDIA_QUERY,
  })
  const isMobile = useMediaQuery({
    query: MOBILE_MEDIA_QUERY,
  })
  return (
    <StyledParentButtonsActionsInMobile>
      <Row style={isMobile ? { margin: '0' } : {}}>
        <ReverseCol
          smallerWidth={smallerWidth}
          smallerMargin={'auto'}
          tabletWidth={
            stepNumber === FIRST_STEP && !screenerCompleted ? '100%' : '70%'
          }
          tabletMargin={tabletMargin}
        >
          {!isDesktop && (
            <>
              <ScreenerBackButton
                margin={isMobile ? '0' : '0 1rem 0 0'}
                width={!isMobile ? '180px' : '100%'}
              />
              <CallsToAction
                {...props}
                width={!isMobile ? '180px' : '100%'}
                invalid={invalid}
              />
            </>
          )}

          {isDesktop && (
            <>
              <ScreenerBackButton
                margin={'0 0 0 0.9375rem '}
                width={'180px'}
                padding='0 3rem'
              />
              <StyledCallsToActionParent>
                <CallsToAction {...props} width='180px' invalid={invalid} />
              </StyledCallsToActionParent>
            </>
          )}
        </ReverseCol>
      </Row>
    </StyledParentButtonsActionsInMobile>
  )
}
export default ScreenerActions
