import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import DOMPurify from 'dompurify'
import { API_BASE } from 'Shared/constants'

/**
 * Makes the API call to get a location.
 * @param {string} organization
 * @param {string} location
 * @returns {Promise} promise from axios get
 */
const getLocationBranding = (organization = 'single-stop', location = 'hq') =>
  axios.get(`${API_BASE}/organizations/${organization}/locations/${location}`)

/**
 * Branding Hook. Attempts to get the branding information for a specific
 * location. If the call fails, returns an empty object - {}. The location
 * has to have the header, footer, and/or logo configured in Active Admin
 * otherwise the API will return an empty object - {}.
 *
 * @returns {{footer:string, header:string, logo:string, content: string} || {}} location branding object
 */

type Branding = {
  footer: string
  header: string
  logo: string
  content: string
  loading: boolean
}
const useBranding = () => {
  const [branding, setBranding] = useState<
    Branding | { loading: boolean; logo?: string }
  >({
    loading: true,
  })
  const { organization, location } = useParams()
  useEffect(() => {
    getLocationBranding(organization, location)
      .then(({ data: { footer, header, logo, content } }) =>
        setBranding({
          footer,
          header,
          logo,
          content: content ? DOMPurify.sanitize(content) : undefined,
          loading: false,
        })
      )
      .catch(() => setBranding({ loading: false }))
  }, [organization, location])

  return branding
}

export default useBranding
