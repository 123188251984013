import {
  CalculateScreeningResultsMutation,
  ServiceCase,
} from '__generated__/graphql'
import { sortCaseMainCategories } from 'Components/CaseManagement/helpers'
import { calculateRecurringBenefitAmount } from 'Pages/Results/helpers'
import categoryIconPathsMap from 'Shared/Icons/CategoryIcons'

const UNLIKELY = 'UNLIKELY'

const updateAccumulator = (
  accumulator: Record<string, unknown>,
  serviceCase: ServiceCase,
  eligibleResultsByServiceId: Record<string, { alreadyReceiving: boolean }>
) => {
  const {
    clientLocation: { followUp },
    service,
    people,
    eligibleAmount,
  } = serviceCase
  const { name, openEligibilityKey } = service.mainCategory

  if (typeof accumulator[openEligibilityKey] === 'undefined') {
    accumulator[openEligibilityKey] = {
      category: name,
      categoryIconPath: categoryIconPathsMap[openEligibilityKey],
      programs: [],
    }
  }

  accumulator[openEligibilityKey].programs.push({
    id: service.id,
    alreadyReceiving: eligibleResultsByServiceId[service.id]?.alreadyReceiving,
    name: service.name,
    amountLabel: service?.amountLabel,
    description: service.description,
    eligible: serviceCase.eligibility !== UNLIKELY,
    findOutMore: followUp,
    link: service.url,
    toolkitUrl: service.toolkitUrl,
    people: people?.map((person) => person.fullName),
    eligibleAmount: eligibleAmount,
    frequency: service.frequency,
    recurringAmount: calculateRecurringBenefitAmount({
      eligibleAmount,
      service,
    }),
  })

  return accumulator
}

/**
 * The Benefits Categories Selector gets the data for client benefits
 *
 * @param  {Object} data clientLocation data
 * @return {Array}       Benefits Categories for rendering
 */
export const categoriesSelector = (data: CalculateScreeningResultsMutation) => {
  if (data && data?.calculateScreeningResults?.screening) {
    const { screening, screeningResults } = data.calculateScreeningResults
    const { serviceCases } = screening
    const { likelyEligibleResults, unlikelyEligibleResults } =
      screeningResults || {}
    const eligibleResults = [
      ...(likelyEligibleResults || []),
      ...(unlikelyEligibleResults || []),
    ]

    const eligibleResultsByServiceId = eligibleResults.reduce(
      (accumulator, result) => {
        accumulator[result.service.id] = result
        return accumulator
      },
      {} as Record<string, (typeof likelyEligibleResults)[0]>
    )

    if (!serviceCases || serviceCases.length === 0) {
      return []
    }
    const caseMainCategories = serviceCases.reduce(
      (accumulator, serviceCase) =>
        updateAccumulator(
          accumulator,
          serviceCase as ServiceCase,
          eligibleResultsByServiceId
        ),
      {}
    )

    return Object.keys(caseMainCategories)
      .sort(sortCaseMainCategories)
      .map((openEligibilityKey) => caseMainCategories[openEligibilityKey])
  }
  return []
}
