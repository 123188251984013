import React from 'react'
import { API_BASE } from 'Shared/constants'

import useCommunityResourcesServiceTags from './useCommunityResourcesServiceTags'

const URL = `${API_BASE}/community_resources/programs/taxonomy`

export type Category = {
  label: string
  children?: Category[]
  count?: string
}

export type TopLevelCategory = {
  key: string
  id: string | undefined
  label: string
  children: Category[]
  description: string
}

type CategoriesResponse = {
  nodes: TopLevelCategory[]
}

function mapCategoriesWithCount(
  categories: (TopLevelCategory | Category)[],
  tags: ReturnType<typeof useCommunityResourcesServiceTags>['serviceTags'],
  depthLevel = 0
): TopLevelCategory[] | Category[] {
  // if depthLevel is 2, we are at the lowest level of the category tree and must return the count
  if (depthLevel === 2) {
    return categories.map((category) => {
      const tag = tags.find((tag) => tag.id === category.label.toLowerCase())
      return {
        ...category,
        count: tag?.count,
      }
    })
  }

  return categories.map((category) => {
    if (!category.children) {
      return category
    }

    return {
      ...category,
      children: mapCategoriesWithCount(category.children, tags, depthLevel + 1),
    }
  })
}

const useCommunityResourcesCategories = (zipCode: string) => {
  const [categories, setCategories] = React.useState<TopLevelCategory[]>([])
  const [loading, setLoading] = React.useState<boolean>(true)
  const [error, setError] = React.useState<string | null>(null)
  const communityResources = useCommunityResourcesServiceTags(zipCode)

  React.useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch(URL)
        if (!response.ok) {
          throw new Error('Network response was not ok')
        }
        const data: CategoriesResponse = await response.json()
        setCategories(data.nodes)
      } catch (err) {
        if (err instanceof Error) {
          setError(err.message)
        } else {
          setError('An unknown error occurred')
        }
      } finally {
        setLoading(false)
      }
    }

    fetchCategories()
  }, [])

  return React.useMemo(
    () => ({
      categories: (communityResources.serviceTags.length > 0
        ? mapCategoriesWithCount(categories, communityResources.serviceTags)
        : categories) as TopLevelCategory[],
      loading,
      error,
    }),
    [categories, loading, error, communityResources]
  )
}

export default useCommunityResourcesCategories
