import _get from 'lodash.get'
import { axiosWithoutAuthHeaders } from 'Shared/axiosInstances'
import {
  ERROR_GENERIC_MESSAGE,
  ERROR_INVALID_ADDRESS_COMPONENT,
  ERROR_INVALID_ZIP_CODE,
  GOOGLE_API_GEOCODE_URL_WITH_KEY,
  PERMITTED_STATE_NAMES,
} from 'Shared/constants'
import { isFeatureToggleOn } from 'Shared/helpers'

/**
 * [ADDRESS_COMPONENTS path to address data in Google's API results]
 * @type {String}
 */
const ADDRESS_COMPONENTS = 'data.results[0].address_components'

/**
 * [ADDRESS_POSTAL_CODE name of the address component for zip code]
 * @type {String}
 */
const ADDRESS_POSTAL_CODE = 'postal_code'

/**
 * [ADDRESS_STATE name of the address component for state]
 * @type {String}
 */
const ADDRESS_STATE = 'administrative_area_level_1'

/**
 * [ADDRESS_CITY name of the address components (multiple) for city]
 * :locality, :sublocality, :administrative_area_level_3, :administrative_area_level_2
 * @type {Array<String>}
 *
 * 'neighborhood', 'political'
 * 'political', 'sublocality', 'sublocality_level_1'
 * 'political', 'sublocality', 'sublocality_level_1'
 *
 * :locality, :sublocality,
 :administrative_area_level_3,
 :administrative_area_level_2
 */
const ADDRESS_CITY = [
  'locality',
  'sublocality',
  'administrative_area_level_3',
  'administrative_area_level_2',
]

/**
 * [ADDRESS_COUNTY name of the address component for county]
 * @type {String}
 */
const ADDRESS_COUNTY = 'administrative_area_level_2'

/**
 * [ADDRESS_COUNTRY name of the component for country]
 * @type {[type]}
 */
const ADDRESS_COUNTRY = 'country'

/**
 * [LONG_NAME address component type for long names, such as 'New York' vs. 'NY']
 * @type {String}
 */
const NAME_TYPE_LONG_NAME = 'long_name'

/**
 * Digs the address result out of Google API results
 *
 * @param  {[type]} result [description]
 * @return {[type]}        [description]
 */
const getAddressResult = (result) => _get(result, ADDRESS_COMPONENTS, [])

/**
 * Digs a specific property out of Google API results
 *
 * @param  {[type]} name) [description]
 * @return {[type]}       [description]
 */
const getAddressComponentValue = (result, name, nameType) =>
  _get(
    getAddressResult(result).find((component) =>
      component.types.includes(name)
    ),
    nameType
  )

/**
 * Digs the postal code out of Google API results
 *
 * @param  {[type]} result [description]
 * @return {[type]}        [description]
 */
export const getZipCode = (result) =>
  getAddressComponentValue(result, ADDRESS_POSTAL_CODE, NAME_TYPE_LONG_NAME)

/**
 * Digs the state name out of Google API results
 *
 * @param  {[type]} result) [description]
 * @return {[type]}         [description]
 */
export const getStateName = (result) =>
  getAddressComponentValue(result, ADDRESS_STATE, NAME_TYPE_LONG_NAME)

/**
 * Digs the city out of Google API results. Uses the same technique as the
 * backend for marshalling the city name.
 *
 * @param  {[type]} result [description]
 * @return {[type]}        [description]
 */
export const getCity = (result) => {
  let value
  for (var i = 0; i < ADDRESS_CITY.length; i++) {
    value = getAddressComponentValue(
      result,
      ADDRESS_CITY[i],
      NAME_TYPE_LONG_NAME
    )
    if (value) {
      return value
    }
  }
  return ERROR_INVALID_ADDRESS_COMPONENT
}

/**
 * Digs the county out of Google API results
 * @param  {[type]} result [description]
 * @return {[type]}        [description]
 */
export const getCounty = (result) =>
  getAddressComponentValue(result, ADDRESS_COUNTY, NAME_TYPE_LONG_NAME)

/**
 * Digs the country out of Google API results
 *
 * @param  {[type]} result [description]
 * @return {[type]}        [description]
 */
export const getCountry = (result) =>
  getAddressComponentValue(result, ADDRESS_COUNTRY, NAME_TYPE_LONG_NAME)

/**
 * Gets physical address object
 *
 *  physical_address: {
 *    address_line_1: optional,
 *    address_line_2: optional,
 *    city: Required,
 *    state: Required,
 *    zip_code: Required,
 *    county: required,
 *    country: required
 *  }
 *
 * @param  {[type]} result [description]
 * @return {[type]}        [description]
 */
export const getPhysicalAddress = (result) => ({
  city: getCity(result),
  state: getStateName(result),
  zip_code: getZipCode(result),
  county: getCounty(result),
  country: getCountry(result),
})

export const getStateNames = () => {
  let states = [...PERMITTED_STATE_NAMES]
  if (isFeatureToggleOn('arkansas_benefits')) {
    states.push('Arkansas')
  }
  return states
}

/**
 * Returns true/false if the state name found in the API result has screening
 *
 * @param  {[type]} result [description]
 * @return {[type]}        [description]
 */
export const getStateIsPermitted = (result) =>
  getStateNames().includes(getStateName(result))

/**
 * Makes the axios call to Google
 *
 * @param  {Number}   zipCode   5 digit zip code
 * @return {Promise}
 */
const getAddress = (zipCode) =>
  new Promise((resolve, reject) =>
    axiosWithoutAuthHeaders
      .get(
        `${GOOGLE_API_GEOCODE_URL_WITH_KEY}&components=postal_code:${zipCode}|country:us`
      )
      .then((result) =>
        _get(result, 'data.status') === 'OK'
          ? result
          : reject({ zipCode: ERROR_INVALID_ZIP_CODE })
      )
      .then((result) => {
        const hasStateBenefits = getStateIsPermitted(result)
        if (result && !hasStateBenefits) {
          const stateName = getStateName(result)
          window.localStorage.setItem(
            'noStateBenefitWarning',
            `For individuals in ${stateName}, we offer screening for federal benefits only.`
          )
        }
        return resolve(result)
      })
      .catch(() => {
        reject({ zipCode: ERROR_GENERIC_MESSAGE })
      })
  )

/**
 * <p>Takes a zip code (postal code) and rejects with errors, or returns the raw
 * results from Google. Errors include:
 * <ul>
 *   <li>zipCode === <code>undefined/null</code>: rejects with <code>undefined</code></li>
 *   <li>zipCode not found: rejects with <code>{ zipCode: ERROR_INVALID_ZIP_CODE }</code></li>
 *   <li>HTTP error, Network error: rejects with <code>{ zipCode: ERROR_GENERIC_MESSAGE }</code></li>
 *   <li></li>
 * </ul>
 * </p>
 *
 * @param  {Number} zipCode 5 digit zip code
 * @return {Promise}
 */
export const getAddressByPostalCode = (zipCode) =>
  new Promise((resolve, reject) => {
    if (!zipCode) {
      reject()
    } else {
      getAddress(zipCode).then(resolve).catch(reject)
    }
  })
