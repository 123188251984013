import React from 'react'

import {
  DescriptionContent,
  DescriptionWrapper,
  DescriptionWrapperList,
  DescriptionWrapperListItem,
} from '../../Description/style'

const ProgramEligibility = ({ items }: { items: string[] }) => {
  return (
    <DescriptionWrapper>
      <DescriptionWrapperList>
        {items.map((item, index) => (
          <DescriptionWrapperListItem key={index}>
            <DescriptionContent>{item}</DescriptionContent>
          </DescriptionWrapperListItem>
        ))}
      </DescriptionWrapperList>
    </DescriptionWrapper>
  )
}

export default ProgramEligibility
