import React from 'react'
import MediaQuery from 'react-responsive'
import { SwipeableDrawer, useMediaQuery } from '@material-ui/core'
import { updateFilterLevel } from 'Actions/filters'
import ActionToggle from 'Components/Shared/ActionToggle/ActionToggle'
import Icon from 'Components/Shared/Icon/Icon'
import { StyledMobileContainer } from 'Pages/LocationDetail/style'
import { handleToggleFilterMenu } from 'Reducers/uiSlice'
import {
  CUSTOM_MOBILE_MEDIA_QUERY,
  CUSTOM_TABLET_MEDIA_QUERY,
  DESKTOP_MEDIA_QUERY,
} from 'Shared/constants'
import {
  categoriesArrowBackIconPath,
  categoriesBlackIconPath,
  categoriesCloseIconPath,
  categoriesWhiteIconPath,
} from 'Shared/Icons/CategoryIcons/CategoriesFilterIconsPath'
import { useAppDispatch, useAppSelector } from 'Store/hooks'

import CategoryFilterMegaMenu from '../CategoryFilter/CategoryFilterMegaMenu'

import { StyledSubHeading } from './style'
import {
  StyledFilterBackLevelButton,
  StyledFilterMenu,
  StyledHeaderFilter,
  StyledParentFilter,
} from './style'

const FilterMenu = () => {
  const dispatch = useAppDispatch()
  const { showFilterMenu } = useAppSelector((state) => state.ui)
  const isDesktop = useMediaQuery(DESKTOP_MEDIA_QUERY)

  const toggleFilterMenu = () => {
    dispatch(handleToggleFilterMenu())
  }

  const { currentFilterLevel, topLevelLabel, secondLevelLabel } =
    useAppSelector((state) => state.filters?.categoriesFilter)

  const handleFilterBackLevel = () => {
    if (currentFilterLevel === 'second') {
      dispatch(updateFilterLevel('first'))
    } else {
      dispatch(updateFilterLevel('second'))
    }
  }

  if (!showFilterMenu) return null
  return (
    <>
      {!isDesktop && (
        <>
          <MediaQuery query={CUSTOM_MOBILE_MEDIA_QUERY}>
            <SwipeableDrawer
              variant='temporary'
              hideBackdrop
              disableAutoFocus
              draggable
              PaperProps={{
                style: {
                  overflowX: 'hidden',
                  height: '90%',
                  overflowY: 'scroll',
                  borderRadius: '2.5rem 2.5rem 0 0',
                  paddingTop: '0rem',
                },
              }}
              style={{ zIndex: 99999 }}
              disableBackdropTransition
              anchor={'bottom'}
              open={showFilterMenu}
              onOpen={() => toggleFilterMenu()}
              onClose={() => toggleFilterMenu()}
              swipeAreaWidth={54}
              ModalProps={{
                keepMounted: false,
              }}
            >
              <CategoryFilterMenuHeader
                currentFilterLevel={currentFilterLevel}
                handleFilterBackLevel={handleFilterBackLevel}
                topLevelLabel={topLevelLabel}
                secondLevelLabel={secondLevelLabel}
                toggleFilterMenu={toggleFilterMenu}
                showFilterMenu={showFilterMenu}
              />
              <StyledMobileContainer>
                <CategoryFilterMegaMenu showResults={toggleFilterMenu} />
              </StyledMobileContainer>
            </SwipeableDrawer>
          </MediaQuery>

          <MediaQuery query={CUSTOM_TABLET_MEDIA_QUERY}>
            <StyledParentFilter className='categories-filter__wrapper'>
              <CategoryFilterMenuHeader
                currentFilterLevel={currentFilterLevel}
                handleFilterBackLevel={handleFilterBackLevel}
                topLevelLabel={topLevelLabel}
                secondLevelLabel={secondLevelLabel}
                toggleFilterMenu={toggleFilterMenu}
                showFilterMenu={showFilterMenu}
              />
              <StyledFilterMenu className='categories-filter--menu'>
                <CategoryFilterMegaMenu showResults={toggleFilterMenu} />
              </StyledFilterMenu>
            </StyledParentFilter>
          </MediaQuery>
        </>
      )}
    </>
  )
}

export default FilterMenu

type CategoryFilterMenuHeaderProps = {
  currentFilterLevel: string
  handleFilterBackLevel: () => void
  topLevelLabel: string
  secondLevelLabel: string
  toggleFilterMenu: () => void
  showFilterMenu: boolean
}
const CategoryFilterMenuHeader = ({
  currentFilterLevel,
  handleFilterBackLevel,
  topLevelLabel,
  secondLevelLabel,
  toggleFilterMenu,
  showFilterMenu,
}: CategoryFilterMenuHeaderProps) => {
  return (
    <StyledHeaderFilter>
      {currentFilterLevel !== 'first' && (
        <StyledFilterBackLevelButton onClick={handleFilterBackLevel}>
          <Icon
            fill={'none'}
            scale={'1.875rem'}
            viewBox={'0 0 24 24'}
            margin={'0 .5rem 0 0'}
            screenReaderLabel={'Categories Filter'}
          >
            {categoriesArrowBackIconPath}
          </Icon>
        </StyledFilterBackLevelButton>
      )}
      <StyledSubHeading className='categories-filter--title'>
        {currentFilterLevel === 'first' ? (
          <Icon
            fill={'none'}
            scale={'1.25rem'}
            viewBox={'0 0 24 24'}
            margin={'0 .5rem 0 0'}
            screenReaderLabel={'Categories Filter'}
          >
            {categoriesBlackIconPath}
          </Icon>
        ) : (
          ''
        )}
        {currentFilterLevel === 'second' ? topLevelLabel : ''}
        {currentFilterLevel === 'all' ? secondLevelLabel : ''}
        {currentFilterLevel === 'first' ? 'Categories' : ''}
      </StyledSubHeading>
      <ActionToggle
        filterInMobile
        activeIconPath={categoriesCloseIconPath}
        activeLabel={'filters'}
        onClick={toggleFilterMenu}
        inactiveIconPath={categoriesWhiteIconPath}
        fillIcon={'none'}
        inactiveLabel={'filters'}
        showMenu={showFilterMenu}
      />
    </StyledHeaderFilter>
  )
}
