import { Link } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import Radio from '@material-ui/core/Radio'
import { withStyles } from '@material-ui/core/styles'
import { MOBILE_MEDIA_QUERY } from 'Shared/constants'
import {
  singleStopDarkerGray,
  singleStopGreen,
  singleStopLightGray,
} from 'Shared/Theme'
import theme from 'Shared/Theme/ssTheme'
import { media } from 'styled-bootstrap-grid'
import styled, { css } from 'styled-components'

type StyledWrapperProps = {
  padding?: string
  background?: string
  minHeight?: string
}
export const StyledWrapper = styled.div<StyledWrapperProps>`
  padding: ${(props) => (props.padding ? props.padding : '2.1rem 1rem')};
  color: ${(props) => props.theme.text.default};
  background-color: ${(props) => props.background};
  font-size: 1.7rem;
  min-height: ${(props) => props.minHeight};
  ${media.smaller`
    padding: 0 0 7rem 0;
  `}
  ${media.tablet`
    padding: 0 0 7rem 0;
  `}
  ${media.desktop`
    padding: 0 0 1rem 0;
  `}
`

export const StyledWrapperCTA = styled.div`
  text-align: center;
  padding: 4.7rem 1.5rem 0;
`

export const StyledHeadingMain = styled.h1`
  font-size: 2.4rem;
  text-align: center;
  text-transform: capitalize;
  line-height: 3.3rem;
  margin-bottom: 1.7rem;
  margin-top: 0;
  &:focus {
    outline: 0;
  }
  @media only screen and (max-width: 350px) {
    font-size: 2.2rem;
  }
`

export const StyledHeadingSecondary = styled.h1`
  font-size: 2rem;
  text-align: center;
  text-transform: capitalize;
  line-height: 3.3rem;
  margin-bottom: 1.7rem;
  margin-top: 0;
  &:focus {
    outline: 0;
  }
  @media only screen and (max-width: 350px) {
    font-size: 2.2rem;
  }
`

export const StyledHeadingLocation = styled.h2`
  font-size: 2rem;
  font-weight: 400;
  text-overflow: ellipsis;
  min-width: 0;
  white-space: nowrap;
  overflow: hidden;
  margin: 0;
`

export const StyledReferralNumberWrapper = styled.div`
  border: 0.1rem solid ${singleStopLightGray};
  margin: 1.5rem 0;
  padding: 1.1rem;
  border-radius: 0.5rem;
`

export const StyledHeadingReferralLabel = styled.h1`
  margin-top: 0;
  margin-bottom: 0.4rem;
  font-size: 2.2rem;
  text-align: center;
  text-transform: capitalize;
`

export const StyledHeadingReferralCode = styled.h1`
  font-size: 2.2rem;
  text-align: center;
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 0;
`

export const StyledLink = styled(Link)`
  text-decoration: underline;
  color: ${(props) => props.theme.text.default};

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    color: ${(props) => props.theme.text.default};
  }
`

const customButtonLabelProps = {
  textTransform: 'capitalize',
  fontWeight: 600,
  letterSpacing: '.1rem',
  '@media only screen and (max-width: 350px)': {
    fontSize: '1.2rem',
  },
}

const customButtonRootProps = {
  borderRadius: 3,
  height: 38,
  padding: '0 1rem',
}

const styledButtonOutlinedRootProps = {
  ...customButtonRootProps,
  borderColor: `${singleStopDarkerGray}`,
  color: `${singleStopDarkerGray}`,
  width: '100%',
  '@media only screen and (min-width: 735px)': {
    marginTop: 'auto',
  },
}

export const StyledButtonOutlined = withStyles({
  root: styledButtonOutlinedRootProps,
  label: customButtonLabelProps,
})(Button)

export const StyledButtonFilled = withStyles({
  root: {
    ...customButtonRootProps,
    margin: '4rem 0 0',
    background: theme.palette.primary.dark,
    color: '#fff',
    width: '100%',
    boxShadow: 'none',
    fontWeight: 500,
    fontSize: '1.125rem',
    borderRadius: '0.3rem',
    height: '3.8rem',
    '&:hover': {
      //old #20e2a7
      background: theme.palette.primary.dark,

      boxShadow: 'none',
    },
    '&:disabled': {
      width: '50%',
    },

    '@media only screen and (min-width: 735px)': {
      marginTop: 'auto',
    },
  },
  label: customButtonLabelProps,
})(Button)

export const StyledIconWrapper = styled.div``

export const StyledLineBreak = styled.div`
  display: flex;
  justify-content: center;
  margin: 2rem 0;

  &::before {
    margin-right: 0.5rem;
  }
  &::after {
    margin-left: 0.5rem;
  }

  &::before,
  &::after {
    position: relative;
    top: 0.8rem;
    flex: 1;
    content: '';
    border-top: 0.1rem solid ${singleStopLightGray};
    height: 0.1rem;
    display: block;
  }
`

export const StyledParagraph = styled.p`
  line-height: 1.8rem;
  margin: 1.7rem 1.5rem;
  ${(props) => (props.warning ? 'color: red' : '')}
`

export const StyledBenefitContainer = styled.div`
  border: 0.08rem solid rgba(0, 0, 0, 0.12);
  margin-bottom: 1.5rem;
`

export const StyledBenefitHeader = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 0.08rem solid rgba(0, 0, 0, 0.12);

  padding: 1.4rem;
`

export const StyledBenefitLabel = styled.span`
  margin-left: 1rem;
  font-size: 1.6rem;
  font-weight: 500;
  color: ${(props) => props.theme.text.default};
`

export const StyledBenefitList = styled.ul`
  padding: 0;
  list-style-type: none;
  margin: 0;
`

export const StyledBenefitListItem = styled.li`
  align-items: center;
  padding: 1.5rem;
  &:not(:last-child) {
    border-bottom: 0.08rem solid rgba(0, 0, 0, 0.12);
  }
`

export const StyledBenefitListItemDescription = styled.p`
  line-height: 2.3rem;
`

export const StyledBenefitListItemExpandedContent = styled.div`
  margin: 0.8rem;
  margin-top: 1.5rem;
`
export const StyledBenefitListItemHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const StyledBenefitListItemLabel = styled.span``

type StyledBenefitListItemToggleButtonProps = {
  eligible: boolean
  background?: string
  color?: string
  fontSize?: string
  fontWeight?: string
  padding?: string
  paddLeft?: string
  border?: string
  radius?: string
  margin?: string
  flex?: string
}
export const StyledBenefitListItemToggleButton = styled.button<StyledBenefitListItemToggleButtonProps>`
  position: relative;
  display: flex;
  flex: ${(props) => (props.flex ? props.flex : '0 0 12.7rem')};
  align-items: center;
  justify-content: space-between;
  background: ${(props) =>
    props.eligible ? props.background || singleStopGreen : 'none'};
  color: ${(props) =>
    props.eligible
      ? props.color || 'rgb(65, 72, 52)'
      : props.color || props.theme.text.default};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '1.3rem')};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : '400')};
  padding: ${(props) =>
    props.padding ? props.padding : '0.3rem 0.5rem 0.3rem 0.7rem'};
  padding-left: ${(props) =>
    props.eligible ? props.paddLeft || '1.5rem' : props.paddLeft || '.7rem'};
  border-radius: ${(props) => (props.radius ? props.radius : '10rem')};
  border: ${(props) =>
    props.eligible
      ? props.border || 'none'
      : props.border || `0.0625rem solid ${props.theme.text.default}`};
  margin: ${(props) => props.margin};
`

export const StyledLocationCard = styled.div`
  border: 0.1rem solid ${singleStopLightGray};
  margin-bottom: 1rem;
  padding: 0 1rem 1rem 1.5rem;
  border-radius: 0.5rem;
`

export const StyledLocationCardInfoContainer = styled.div``

export const StyledInfo = styled.div`
  font-size: 1.4rem;
  margin-left: 0.5rem;
`

export const StyledInfoContainer = styled.div`
  display: flex;
  margin-bottom: 0.5rem;
`

export const StyledLocationCardHeadingWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const StyledRadio = withStyles({
  root: {
    // color: 'red',
  },
})(Radio)

export const StyledDocumentationList = styled.ul`
  padding: 0;
  list-style-type: none;
  margin: 0;
  box-shadow: 0 3px 6px #1d283117;
`

export const StyledDocumentationListItem = styled.li`
  &:not(:last-child) {
    border-bottom: 0.08rem solid rgba(0, 0, 0, 0.12);
  }
`

export const StyledDocumentationListItemHeading = styled.h2`
  display: flex;
  align-items: center;
  font-size: 1rem;
  font-weight: 400;
  margin: 0;
  margin-right: auto;
  position: relative;
  padding-left: 0.5rem;
  top: -1px;
  @media only screen and (max-width: 420px) {
    font-size: 1.5rem;
  }
`

export const StyledDocumentationListItemHeadingWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`

export const StyledDocumentationListItemButton = styled.button`
  background: none;
  border: none;
`

export const StyledDocumentationListItemExpandedDetail = styled.div<{
  display: string
}>`
  margin: 2rem 3rem;
  display: ${(props) => props.display};
`

export const StyledDocumentationExamples = styled.ul`
  padding: 0;
  list-style-type: none;
  margin: 0;
`

export const StyledDocumentationExample = styled.li`
  display: flex;
  align-items: center;
`

export const StyledDocumentationExampleLabel = styled.div`
  text-transform: capitalize;
`

export const StyledBookmarkButton = styled.button`
  background: none;
  border: none;
  color: ${(props) => props.theme.text.default};
  text-decoration: underline;
  &:hover {
    cursor: pointer;
  }
`

export const StyledBookmarkSpan = styled.span`
  text-decoration: underline;
`
type StyledBenefitAmountProps = {
  highlight?: boolean
  small?: boolean
}
export const StyledBenefitAmount = styled.span<StyledBenefitAmountProps>`
  font-family: 'Roboto';
  font-size: 0.75rem;
  color: ${theme.palette.text.Lighten};
  ${(props) =>
    props.highlight &&
    css`
      color: ${theme.palette.primary.dark};
      font-weight: 700;
    `}
  ${(props) =>
    props.small &&
    css`
      font-size: 0.6875rem;
    `}
`

export const StyledBenefitsSpan = styled.span`
  color: ${(props) => props.theme.text.default};
  font-size: 0.6875rem;
`

export const StyledPeopleParent = styled.div`
  display: inline-block;
  text-align: left;
`

export const StyledPeople = styled(StyledBenefitsSpan)`
  font-weight: 700;
  margin: 0 0 0 0.2rem;
  text-align: left;
`

export const StyledFlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 1.3rem 0;
  gap: 1rem;
  @media ${MOBILE_MEDIA_QUERY} {
    flex-direction: column;
    margin: 0;
  }
  .eligibilityRateDisplayCards {
    flex-shrink: 0;
    @media only screen and (max-width: 480px) {
      margin-bottom: 1.3rem;
    }
  }
  @media only screen and (max-width: 850px) {
    .non-cash-benefits-tooltip {
      transform: translateX(-50.5%);
    }
  }
`

type StyledHeadingProps = {
  $nextSteps?: boolean
  categoryTitle?: boolean
}
export const StyledHeading = styled.h2<StyledHeadingProps>`
  line-height: 1.75rem;
  font-family: 'Noto Sans HK', sans-serif;
  font-size: 1.2rem;
  font-weight: 300;
  margin: 1.875rem 0;
  padding-right: 0.75rem;
  color: ${theme.palette.text.default};
  @media ${MOBILE_MEDIA_QUERY} {
    font-size: 1rem;
    margin: 1.5rem 0;
    line-height: 1.5;
    padding-right: 0;
  }

  ${({ $nextSteps }) =>
    $nextSteps &&
    css`
      margin: 0;
      a {
        color: ${theme.palette.primary.dark};
        margin: auto 0.25rem;
      }
    `}

  ${({ categoryTitle }) =>
    categoryTitle &&
    css`
      margin: 0;
      font-size: 1.125rem;
      font-weight: 500;
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    `}
`

export const StyledMain = styled.main`
  width: 100%;
  height: 100%;
`

export const StyledFocusButtonWrapper = styled.div`
  display: flex;
  &:focus {
    border: 2px solid #0d8762;
  }
`

export const StyledBenefitHeading = styled.div`
  display: inline-flex;
  alignitems: flex-end;
  &.StyledBenefitHeading--custom-style {
    justify-content: space-between;
    display: flex;
    font-weight: 600;
    font-size: 14px;
    line-height: 22.4px;
    font-family: 'Noto Sans HK', sans-serif;
  }
  .StyledBenefitHeading__button {
    border: none !important;
  }
  .StyledBenefitHeading__heading {
    width: calc(100% - 30px);
    span {
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    // white-space:nowrap;
    // overflow:hidden;
    // display:block;
    // text-overflow: ellipsis;
  }
  [data-testid='more-info'] {
    width: 24px;
    height: 24px;
    svg {
      fill: #595959 !important;
    }
  }
  &:hover {
    cursor: pointer;
  }
`

export const StyledBenefitGuideLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 0.75rem;
  font-weight: 400;
  color: ${theme.palette.color.black};
  text-decoration: unset;
  margin: 0.25rem 0;
  &:hover {
    cursor: pointer;
  }
`

export const StyledList = styled.ul`
  list-style-type: none;
  display: flex;
  padding: 0;
  margin: 0;
  flex-direction: column;
  ${media.sm`
    margin-bottom: 5rem;
  `}
`

export const StyledListItem = styled.li``

StyledBenefitAmount.displayName = 'StyledBenefitAmount'
StyledBenefitsSpan.displayName = 'StyledBenefitsSpan'
StyledPeople.displayName = 'StyledPeople'
StyledFlexContainer.displayName = 'StyledFlexContainer'
StyledWrapper.displayName = 'StyledWrapper'
StyledWrapperCTA.displayName = 'StyledWrapperCTA'
StyledHeadingMain.displayName = 'StyledHeadingMain'
StyledHeadingSecondary.displayName = 'StyledHeadingSecondary'
StyledHeadingLocation.displayName = 'StyledHeadingLocation'
StyledReferralNumberWrapper.displayName = 'StyledReferralNumberWrapper'
StyledHeadingReferralLabel.displayName = 'StyledHeadingReferralLabel'
StyledHeadingReferralCode.displayName = 'StyledHeadingReferralCode'
StyledLink.displayName = 'StyledLink'
StyledButtonOutlined.displayName = 'StyledButtonOutlined'
StyledIconWrapper.displayName = 'StyledIconWrapper'
StyledLineBreak.displayName = 'StyledLineBreak'
StyledParagraph.displayName = 'StyledParagraph'
StyledBenefitContainer.displayName = 'StyledBenefitContainer'
StyledBenefitHeader.displayName = 'StyledBenefitHeader'
StyledBenefitLabel.displayName = 'StyledBenefitLabel'
StyledBenefitList.displayName = 'StyledBenefitList'
StyledBenefitListItem.displayName = 'StyledBenefitListItem'
StyledBenefitListItemDescription.displayName =
  'StyledBenefitListItemDescription'
StyledBenefitListItemExpandedContent.displayName =
  'StyledBenefitListItemExpandedContent'
StyledBenefitListItemHeader.displayName = 'StyledBenefitListItemHeader'
StyledBenefitListItemLabel.displayName = 'StyledBenefitListItemLabel'
StyledBenefitListItemToggleButton.displayName =
  'StyledBenefitListItemToggleButton'
StyledLocationCard.displayName = 'StyledLocationCard'
StyledLocationCardInfoContainer.displayName = 'StyledLocationCardInfoContainer'
StyledInfoContainer.displayName = 'StyledInfoContainer'
StyledLocationCardHeadingWrapper.displayName =
  'StyledLocationCardHeadingWrapper'
StyledDocumentationList.displayName = 'StyledDocumentationList'
StyledDocumentationListItem.displayName = 'StyledDocumentationListItem'
StyledDocumentationListItemHeading.displayName =
  'StyledDocumentationListItemHeading'
StyledDocumentationListItemHeadingWrapper.displayName =
  'StyledDocumentationListItemHeadingWrapper'
StyledDocumentationListItemButton.displayName =
  'StyledDocumentationListItemButton'
StyledDocumentationListItemExpandedDetail.displayName =
  'StyledDocumentationListItemExpandedDetail'
StyledDocumentationExamples.displayName = 'StyledDocumentationExamples'
StyledDocumentationExample.displayName = 'StyledDocumentationExample'
StyledDocumentationExampleLabel.displayName = 'StyledDocumentationExampleLabel'
StyledBookmarkButton.displayName = 'StyledBookmarkButton'
StyledBookmarkSpan.displayName = 'StyledBookmarkSpan'
StyledHeading.displayName = 'StyledHeading'
StyledMain.displayName = 'StyledMain'
StyledFocusButtonWrapper.displayName = 'StyledFocusButtonWrapper'
StyledBenefitHeading.displayName = 'StyledBenefitHeading'
StyledBenefitGuideLink.displayName = 'StyledBenefitGuideLink'
StyledList.displayName = 'StyledList'
StyledListItem.displayName = 'StyledListItem'
