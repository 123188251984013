import theme from 'Shared/Theme/ssTheme'
import styled from 'styled-components'

export const StyledDesktopSecondaryActionButton = styled.button<{
  $leftPosition: string
  $transformButton: boolean
}>`
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: ${(props) =>
    props.$leftPosition ? props.$leftPosition : theme.layout.asideWidth};
  transition: left 0.5s ease-in-out;

  border: none;
  padding: 22px 10px;
  border-radius: 0 0.5em 0.5em 0;
  text-align: center;
  background-color: #fff;
  z-index: 10;
  box-shadow: 5px 0 9px 0px rgba(0, 0, 0, 0.1);
  .arrow-icon {
    transform: ${(props) =>
      props.$transformButton ? 'rotate(0)' : 'rotate(180deg)'};
    transition: all ease-in-out 0.3s;
  }
  &:focus {
    background: #f1f1f1;
  }

  @media only screen and (max-width: 574px) {
    display: none;
  }
`

StyledDesktopSecondaryActionButton.displayName =
  'StyledDesktopSecondaryActionButton'
