import { media } from 'styled-bootstrap-grid'
import styled from 'styled-components'

export const StyledTabletAndDesktopSearchBar = styled.div`
  width: 100%;
  padding: 1.8em;
  padding-bottom: 0.5rem;
  z-index: 0;
  position: relative;
  ${media.smaller`
    padding: 0.5rem;
  `}
  @media only screen and (min-width: 481px) and (max-width: 1024px) {
    padding: 1.5rem 1rem;
  }
`

export const StyledTabletAndDesktopSearchBarInner = styled.div`
  display: flex;
  justify-content: space-around;
  .map-search-bar {
    display: flex;
    padding: 0;
    width: 100%;
  }

  .error-message {
    color: #f64744;
    font-size: 1rem;
    font-weight: 500;
    margin-top: 0.5rem;
    ${media.smaller`
      text-align: center;
  `}
  }

  padding: 0;
  margin: 0;

  ${media.smaller`
    padding: .375rem;
    width: 100%;
  `}
  ${media.tablet`
    padding: 0;
    width: 100%;
  `}
  ${media.desktop`
    padding: 0;
    position: relative;
    width: auto;
  `}
`

StyledTabletAndDesktopSearchBar.displayName = 'StyledTabletAndDesktopSearchBar'
StyledTabletAndDesktopSearchBarInner.displayName =
  'StyledTabletAndDesktopSearchBarInner'
