import SelectMenuOptions from 'Components/Shared/ReduxForm/SelectMenu/SelectMenuOptions'

const CalendarDays = (daysCount = 31) => {
  const days = [{ label: '', value: '' }]
  for (let i = 1; i <= daysCount; i++) {
    days.push({
      label: i + '',
      value: i + '',
    })
  }
  return SelectMenuOptions({ options: days, questionLabel: 'days' })
}

export default CalendarDays
