// timeUtils.ts
export type BusinessHour = {
  day: string
  openingTime: string | null
  closingTime: string | null
}

export const getCurrentZoneTime = (timezoneName: string) => {
  return new Date().toLocaleString('en-US', {
    timeZone:
      timezoneName != null && timezoneName !== ''
        ? timezoneName
        : Intl.DateTimeFormat().resolvedOptions().timeZone,
  })
}

export const isWithinWorkingHours = (
  openingTime: string,
  closingTime: string,
  timezoneName: string
): boolean => {
  const currentZoneDate = new Date(getCurrentZoneTime(timezoneName))

  const [openingHour, openingMinute, openingPeriod] = parseTime(openingTime)
  const [closingHour, closingMinute, closingPeriod] = parseTime(closingTime)

  const openingDate = new Date(currentZoneDate)
  const closingDate = new Date(currentZoneDate)

  openingDate.setHours(
    convertTo24HourFormat(openingHour, openingPeriod),
    openingMinute
  )
  closingDate.setHours(
    convertTo24HourFormat(closingHour, closingPeriod),
    closingMinute
  )

  return currentZoneDate >= openingDate && currentZoneDate <= closingDate
}

const parseTime = (time: string): [number, number, string] => {
  const [hourMinute, period] = time.split(' ')
  const [hour, minute] = hourMinute.split(':').map(Number)
  return [hour, minute, period]
}

const convertTo24HourFormat = (hour: number, period: string): number => {
  if (period.toLowerCase() === 'pm' && hour !== 12) {
    return hour + 12
  }
  if (period.toLowerCase() === 'am' && hour === 12) {
    return 0
  }
  return hour
}

export const TodaysClosingTimeFunction = (
  businessHours: BusinessHour[],
  timezoneName: string
) => {
  const today = new Date()
    .toLocaleString('us', { weekday: 'long' })
    .toLowerCase()
  const daysOfWeek = [
    'sunday',
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
  ]
  const todayIndex = daysOfWeek.indexOf(today)

  const todayInfo = businessHours.find((dayInfo) => dayInfo.day === today)

  let isOpenToday = todayInfo && todayInfo?.openingTime !== null
  const closingTime = isOpenToday ? todayInfo?.closingTime : null
  let nextOpenedDayInfo: BusinessHour | null = null

  // Check if current US time is before the opening time today
  if (isOpenToday && todayInfo?.openingTime && todayInfo?.closingTime) {
    const currentZoneDate = new Date(getCurrentZoneTime(timezoneName))
    const [openingHour, openingMinute, openingPeriod] = parseTime(
      todayInfo.openingTime
    )

    const openingDate = new Date(currentZoneDate)
    openingDate.setHours(
      convertTo24HourFormat(openingHour, openingPeriod),
      openingMinute
    )

    // If the current time is before today's opening time, treat today as the next opening day
    if (currentZoneDate < openingDate) {
      nextOpenedDayInfo = todayInfo
      isOpenToday = false
    } else {
      isOpenToday = isWithinWorkingHours(
        todayInfo.openingTime,
        todayInfo.closingTime,
        timezoneName
      )
    }
  }

  // If today is closed, or current time is after closing, find the next opening day
  if (!nextOpenedDayInfo) {
    for (let i = 1; i <= 7; i++) {
      const nextIndex = (todayIndex + i) % 7
      const nextDay = daysOfWeek[nextIndex]
      const nextDayInfo = businessHours.find(
        (dayInfo) => dayInfo.day === nextDay
      )

      if (nextDayInfo && nextDayInfo.openingTime !== null) {
        nextOpenedDayInfo = nextDayInfo
        break
      }
    }
  }

  return { isOpenToday, closingTime, nextOpenedDayInfo }
}
