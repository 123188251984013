import React from 'react'

import MilitaryDateSubSelector from './MilitaryDateSubSelector'

interface MilitaryDateFullSelectorProps {
  meta: { error: string }
  input: { name: string }
}
const MilitaryDateFullSelector = (props: MilitaryDateFullSelectorProps) => {
  return (
    <>
      <MilitaryDateSubSelector
        {...props}
        namePrefix='start'
        text='Start Date'
        startDay={props?.startDay}
        startMonth={props?.startMonth}
        startYear={props?.startYear}
      />
      <MilitaryDateSubSelector
        {...props}
        namePrefix='end'
        text='End Date'
        endDay={props?.endDay}
        endMonth={props?.endMonth}
        endYear={props?.endYear}
      />
    </>
  )
}

export default MilitaryDateFullSelector
