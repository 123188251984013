import React, { useState } from 'react'
import { connect } from 'react-redux'
import MediaQuery from 'react-responsive'
import { useNavigate } from 'react-router-dom'
import { parseQuery } from 'Actions/filters'
import { handlePreventUserTypeLetters } from 'Pages/Locations/helpers'
import {
  DESKTOP_MEDIA_QUERY,
  MOBILE_AND_TABLET_MEDIA_QUERY,
} from 'Shared/constants'
import { AppDispatch, RootState } from 'Store'
import { useAppSelector } from 'Store/hooks'
import { useDebouncedCallback } from 'use-debounce'

import SearchBoxInput from './SearchBoxInput'
import { StyledSurface } from './style'

export type SearchBoxProps = {
  className?: string
  handleSearch: (inputText: string) => void
  initialValue?: string
  setZipCodeError: (error: boolean) => void
  zipCodeError: boolean
}

const SearchBox = (props: SearchBoxProps) => {
  const { handleSearch, initialValue, setZipCodeError, zipCodeError } = props
  const { resourcesZipCode } = useAppSelector((state) => state.geolocation)
  const navigate = useNavigate()

  const debouncedHandleSearchFunction = useDebouncedCallback((inputText) => {
    handleSearch(inputText)
    navigate('/locations/list', { replace: true })
  }, 500)

  const [elevation, setElevation] = useState(0)

  const handleOnFocus = () => {
    setElevation(3)
  }

  const handleOnBlur = () => {
    setElevation(0)
  }

  return (
    <>
      <MediaQuery query={MOBILE_AND_TABLET_MEDIA_QUERY}>
        <StyledSurface
          background={'none'}
          minHeight={'0'}
          boxShadow={'none'}
          elevation={elevation}
          margin={'0'}
          padding={'0'}
          radius={'0.4rem'}
          mobileWidth={'86%'}
          flex='none'
          className={props.className}
          style={{
            flexShrink: '1',
            flexGrow: '1',
          }}
        >
          <SearchBoxInput
            autoSearch={false}
            handleOnBlur={handleOnBlur}
            handleOnFocus={handleOnFocus}
            debouncedHandleSearchFunction={debouncedHandleSearchFunction}
            initialValue={initialValue}
            onKeyPress={(e) => {
              if (!resourcesZipCode) {
                handlePreventUserTypeLetters(e)
              }
              return true
            }}
            setError={setZipCodeError}
            error={zipCodeError}
            maxCharLimit={!resourcesZipCode ? 5 : undefined}
          />
        </StyledSurface>
      </MediaQuery>

      <MediaQuery query={DESKTOP_MEDIA_QUERY}>
        <StyledSurface
          boxShadow={'none'}
          elevation={elevation}
          margin={'0'}
          padding={'0'}
          radius={'0'}
          minHeight={'10px'}
          width={'100%'}
          className={props.className}
        >
          <SearchBoxInput
            autoSearch={false}
            handleOnBlur={handleOnBlur}
            handleOnFocus={handleOnFocus}
            debouncedHandleSearchFunction={debouncedHandleSearchFunction}
            initialValue={initialValue}
            onKeyPress={(e) => {
              if (!resourcesZipCode) {
                handlePreventUserTypeLetters(e)
              }
              return true
            }}
            setError={setZipCodeError}
            error={zipCodeError}
            maxCharLimit={!resourcesZipCode ? 5 : undefined}
          />
        </StyledSurface>
      </MediaQuery>
    </>
  )
}

const mapStateToProps = (state: RootState) => {
  const { ui } = state
  return {
    ui,
  }
}

const dispatchToProps = (dispatch: AppDispatch) => ({
  handleSearch: (text: string) => {
    dispatch(parseQuery(text, true))
  },
  parseQuery: (query: string) => dispatch(parseQuery(query, true)),
})

const connected = connect(mapStateToProps, dispatchToProps)(SearchBox)

export default connected
