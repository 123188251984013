import React from 'react'
import { useSelector } from 'react-redux'
import { FieldArray } from 'redux-form'
import { screenerSelector } from 'Selectors'

import DateSelectorMilitaryFields from './DateSelectorMilitaryFields'
import { useFields } from './hooks'
import MilitaryDateSelectorFieldArrayRenderField from './MilitaryDateSelectorFieldArrayRenderField'

interface MilitaryDateSelectorFieldArrayProps {
  name: string
  label: string
  meta: {
    error: string
    touched: boolean
  }
  fieldValues: {
    start: string
    end: string
  }[]
}

const MilitaryDateSelectorFieldArray = (
  props: MilitaryDateSelectorFieldArrayProps
) => {
  const fieldValues = useSelector((state) =>
    screenerSelector(state, props.name)
  )

  useFields({ ...props, fieldValues }, {})

  return (
    <DateSelectorMilitaryFields
      {...props}
      FieldType={FieldArray}
      Component={MilitaryDateSelectorFieldArrayRenderField}
      fieldValues={fieldValues && fieldValues}
    />
  )
}

export default MilitaryDateSelectorFieldArray
