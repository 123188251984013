import styled from 'styled-components'

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
`

export const StyedNotificationBanner = styled.div`
  background-color: #f8d7da;
  border-color: #f5c6cb;
  border-radius: 0;
  color: #721c24;
  padding: 0.75rem 1.25rem;
  position: absolute;
  width: 100%;
  z-index: 100;
  height: 50px;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  font-weight: 600;
`

export const MapWrapper = styled.div`
  height: 100% !important;
`
StyledContainer.displayName = 'StyledContainer'
