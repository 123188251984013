import { Card } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import theme from 'Shared/Theme/ssTheme'
import styled, { css } from 'styled-components'

type StyledClientPortalProps = {
  $isEditMode?: boolean
  $isContactLogin?: boolean
  $isDemographic?: boolean
  $margin?: string
}

export const StyledSectionHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
  button {
    font-family: 'Noto Sans HK', sans-serif;
    .MuiButton-label {
      display: inline-flex;
      gap: 8px;
      flex-direction: row-reverse;
    }
    &[data-testid='editButton'],
    &[data-testid='saveButton'] {
      background-color: #08674a;
      color: white;
      border-radius: 56px;
      font-size: 16px;
      font-weight: 600;
      .MuiButton-startIcon {
        margin: 0;
        filter: brightness(0) invert(1);
      }
      @media only screen and (max-width: 1024px) {
        font-size: 14px;
        font-weight: 400;
      }
      @media (max-width: 767px) {
        .MuiButton-startIcon {
          // display: none;
        }
      }
    }
    &[data-testid='saveButton'] {
      .MuiButton-startIcon {
        // display:none;
      }
    }
    &[data-testid='EditButton'] {
      .MuiButton-startIcon {
        // display:none;
      }
    }
    &.cancel-button {
      font-family: 'Noto Sans HK', sans-serif;
      border: 1px solid #efefef;
      background-color: transparent;
      border-radius: 56px;
      font-size: 16px;
      font-weight: 600;
      @media only screen and (max-width: 1024px) {
        font-size: 14px;
        font-weight: 400;
      }
    }
  }
`

export const StyledSectionTitle = styled.h4`
  font-size: 18px;
  font-weight: 600;
  margin: 0;
  font-family: 'Noto Sans HK', sans-serif;
  text-transform: uppercase;
  @media only screen and (max-width: 1024px) {
    font-size: 16px;
  }
`

export const StyledClientPortalSection = styled.div`
  width: 100%;
`
export const StyledCard = withStyles({
  root: {
    // boxShadow: '0px 3px 6px #1D283117',
    boxShadow: 'none',
    borderRadius: '24px',
    padding: '24px',
    width: '100%',
    display: 'flex',
    marginBottom: '8px',
    flexDirection: 'column',
    '@media (max-width: 1024px)': {
      padding: '16px',
    },
    '@media (max-width: 767px)': {
      '&.StyledClientPortalCardContent__isEditing': {
        marginBottom: '50px',
      },
    },
  },
})(Card)

export const StyledCardError = styled.div`
  display: flex;
  width: 100%;
`

export const StyledClientPortalCardContent = styled.div`
  width: 100%;
  margin-bottom: 0;
  @media only screen and (max-width: 767px) {
    .actionButtonsWrapper {
      button {
        &[data-testid='editButton'] {
          border-radius: 50%;
          padding: 0;
          width: 40px;
          height: 40px;
          min-width: unset;
          .MuiButton-label {
            gap: 0;
          }
          .label-text {
            display: none;
          }
        }
      }
      &.actionButtonsWrapper__isEditing {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 8px 16px;
        z-index: 10;
        background-color: white;
        > div {
          display: flex;
          gap: 8px;
        }
        button {
          margin: 0;
          width: 100%;
          font-size: 11px;
          img {
            display: none;
          }
        }
      }
    }
  }
`
export const CustomInfoWrapper = styled.section`
  .container--full-width {
    max-width: 100% !important;
  }
  .fieldWrapper {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    margin-bottom: 0;
    &.fieldWrapper--custom-margin {
      margin-bottom: 24px !important;
      @media only screen and (max-width: 1024px) {
        margin-bottom: 16px !important;
      }
    }
    &:not(:last-child) {
      margin-bottom: 0;
    }
    .fieldWrapper__label,
    .fieldWrapper__value {
      font-size: 14px;
      font-weight: 450;
      font-family: 'Noto Sans HK', sans-serif;
      line-height: 22.4px;
      display: flex;
      opacity: 1;
      width: 100%;
    }
    .fieldWrapper__label {
      color: #595959;
    }
    .fieldWrapper__value {
      margin: 0;
      color: #151515;
    }
  }
  .radioFieldWrapper {
    width: 100%;
    [role='radiogroup'] {
      margin: 0;
      label {
        // background-color:#EEF9F6;
        border-radius: 8px;
        padding: 0 12px 0 0;
        color: #151515;
        width: 100%;
        margin: 0 0 12px 0;
        max-width: 250px;
        font-family: 'Noto Sans HK', sans-serif;
        border: 1px solid #efefef;
        @media only screen and (max-width: 767px) {
          max-width: 100%;
        }
        svg {
          filter: brightness(0%);
        }
        &:has(> span.Mui-checked) {
          background-color: #eef9f6;
          border: 1px solid #eef9f6;
          svg {
            filter: unset;
          }
        }
        span {
          font-size: 14px;
          line-height: unset;
          font-weight: 450;
        }
      }
    }
  }
  .inputWrapper {
    width: 100%;
    &.inputWrapper--custom-margin {
      margin-bottom: 24px !important;
    }
    @media only screen and (max-width: 1024px) {
      &.inputWrapper--custom-margin {
        margin-bottom: 16px !important;
      }
    }
    @media only screen and (max-width: 767px) {
      &.inputWrapper__language {
        label {
          margin-bottom: 5px !important;
        }
      }
    }
    > div {
      border: 1px solid #d8d8d8 !important;
      border-radius: 6px;
      background-color: #f9f9f9;
      padding: 0;
      @media only screen and (max-width: 767px) {
        margin-top: 5px;
      }
    }
    input {
      padding: 16px 16px;
      font-size: 14px;
      font-weight: 450;
      font-family: 'Noto Sans HK', sans-serif;
      line-height: 22.4px;
    }
    button {
    }
    label {
      font-size: 14px;
      font-weight: 450;
      font-family: 'Noto Sans HK', sans-serif;
      line-height: 22.4px;
      display: block;
      opacity: 1;
      color: #151515;
      margin: 0;
    }
    &:not(:last-child) {
      margin-bottom: 0;
    }
    [data-testid='date_of_birth'] {
      border-radius: 6px;
      border: 1px solid #d8d8d8 !important;
      background-color: #f9f9f9;

      padding: 0 10px 0 0;
      margin: 0;
      input {
        margin: 0;
        font-size: 14px !important;
        font-weight: 450;
        font-family: 'Noto Sans HK', sans-serif;
        line-height: 22.4px;
        padding: 13px 16px;
        color: #151515;
      }
      > div {
        line-height: unset !important;
      }
    }
    &.inputWrapper__date {
      > div {
        border: none !important;
        background-color: transparent;
      }
    }
    &.inputWrapper__language {
      #preferred_language-label {
        display: none;
      }
      button {
        &.Mui-focusVisible {
          /* background-color: #e7e7e7 !important; */
          span {
            span:first-child {
              background-color: #3266d0;
              color: white !important;
            }
            span:last-child {
              color: #151515 !important;
            }
          }
        }
      }
      > div {
        border: none !important;
        border-radius: 6px;
        background-color: transparent !important;
        padding: 0;
        > div {
          height: unset !important;
          padding-bottom: 0 !important;
        }
      }
      label {
        margin-bottom: 15px;
      }
      button {
        font-size: 14px;
        font-weight: 450;
        font-family: 'Noto Sans HK', sans-serif;
        line-height: 22.4px;
        padding: 11px 16px;
        background-color: #f9f9f9;
        border: 1px solid #d8d8d8 !important;
        outline: none !important;
        margin-top: 0;
      }
    }
  }
`
export const CustomInfoWrapperItems = styled.div`
  display: grid;
  gap: 24px 40px;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto;
  width: 100%;

  &.CustomInfoWrapperItems__twoColumns {
    grid-template-columns: 1fr 2fr;
    gap: 0px 25px;
  }

  @media only screen and (min-width: 1024px) {
    .first-row-lg {
      grid-row: 1;
    }
    .second-row-lg {
      grid-row: 2;
    }
    .third-row-lg {
      grid-row: 3;
    }
    .first-column-lg {
      grid-column: 1;
    }
    .second-column-lg {
      grid-column: 2;
    }
    .third-column-lg {
      grid-column: 3;
    }
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    .inputWrapper,
    .fieldWrapper {
      max-width: 340px;
    }
    grid-template-columns: 1.5fr 2fr;
    gap: 16px 40px;
    .first-row-md {
      grid-row: 1;
    }
    .second-row-md {
      grid-row: 2;
    }
    .third-row-md {
      grid-row: 3;
    }
    .fourth-row-md {
      grid-row: 4;
    }
    .first-column-md {
      grid-column: 1;
    }
    .second-column-md {
      grid-column: 2;
    }
    .third-column-md {
      grid-column: 3;
    }
    &.CustomInfoWrapperItems__twoColumns {
      gap: 0px 40px;
      grid-template-columns: 1.5fr 2fr;
    }
  }
  @media only screen and (max-width: 767px) {
    grid-template-columns: repeat(1, 1fr);
    gap: 10px 40px;
    &.CustomInfoWrapperItems__twoColumns {
      gap: 10px 40px;
      grid-template-columns: repeat(1, 1fr);
    }
  }
`
export const CustomInfoWrapperItemHolder = styled.div``
export const StyledPersonalInfoNameBox = styled.div``

export const StyledItemBox = styled.div`
  display: flex;
  align-items: start;
  justify-content: flex-start;
  text-transform: capitalize;
  // &:not(:last-child) {
  //   margin-bottom: 1rem;
  // }
  @media only screen and (max-width: 767px) {
    justify-content: space-between;
  }
`

export const StyledName = styled.div`
  font-size: 0.875rem;
  font-weight: 500;
  display: flex;
  word-break: break-word;
  @media (max-width: 767px) {
    width: 45%;
  }
`
export const StyledNameValue = styled.div`
  font-size: 0.875rem;
  font-weight: 700;
  display: flex;
  word-break: break-word;
  margin-inline-start: 0.5rem;
  @media (max-width: 767px) {
    width: 55%;
    margin-inline-start: 0;
  }
`

export const StyledPersonalInfoDataWrapper = styled.div<StyledClientPortalProps>`
  width: 50%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  ${({ $isEditMode }) =>
    $isEditMode &&
    css`
      margin: 0 auto;
      align-items: start;
      justify-content: flex-start;

      & > div {
        margin-bottom: 1rem;
      }
    `};
  @media only screen and (max-width: 767px) {
    width: 100%;
    margin: 0;
  }
`

export const StyledContactLoginDataWrapper = styled(
  StyledPersonalInfoDataWrapper
)`
  width: 40%;
  margin: unset;
  @media only screen and (max-width: 767px) {
    margin-bottom: 2rem;
    border-inline-end: unset;
    width: 100%;
  }
`

export const StyledPersonalInfoDataBox = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  &:not(:last-child) {
    margin-bottom: 1rem;
  }
`

export const StyledInfoDataName = styled.div<StyledClientPortalProps>`
  display: flex;
  font-size: 0.875rem;
  color: ${theme.palette.color.black};
  opacity: 0.7;
  ${({ $isContactLogin }) =>
    $isContactLogin &&
    css`
      width: 45%;
    `};
  ${({ $isDemographic }) =>
    $isDemographic &&
    css`
      width: 25%;
    `};
  @media (max-width: 767px) {
    width: 45%;
  }
`

export const StyledInfoDataValue = styled.div<StyledClientPortalProps>`
  display: flex;
  width: 50%;
  font-size: 0.875rem;
  font-weight: 600;
  color: ${theme.palette.color.black};
  word-break: break-word;
  margin: ${({ $margin }) => $margin ?? 'unset'};
  ${({ $isContactLogin }) =>
    $isContactLogin &&
    css`
      width: 45%;
      display: flex;
      align-items: center;
    `};
  ${({ $isDemographic }) =>
    $isDemographic &&
    css`
      width: 75%;
    `};
  @media (max-width: 767px) {
    width: 55%;
  }
  &.StyledInfoDataValue__label {
    width: 100% !important;
    color: #08674a;
    font-weight: 450;
    line-height: 22px;
    font-size: 14px;
    display: block !important;
  }
`

export const StyledDemographicDataWrapper = styled(
  StyledPersonalInfoDataWrapper
)`
  width: 100%;
  margin: unset;
`

export const StyledContactConsentWrapper = styled(
  StyledPersonalInfoDataWrapper
)`
  max-width: 500px;
  width: 100%;
  align-items: flex-start;
  margin: 0;
  div > h2 {
    margin: 0;
  }
  @media only screen and (max-width: 767px) {
    width: 100%;
    margin-top: 5px;
  }
`

export const StyledConsentTitle = styled.h3`
  font-size: 14px;
  font-family: 'Noto Sans HK', sans-serif;
  font-weight: 650;
  color: #151515;
  margin: unset;
  margin-bottom: 0;
  line-height: 22.4px;
`

export const StyledConsentDescription = styled.p`
  font-size: 14px;
  color: #393946;
  font-family: 'Noto Sans HK', sans-serif;
  opacity: 1;
  margin-bottom: 0;
  margin-top: 8px;
  line-height: 22px;
  margin-bottom: 16px;
  .consent__email-info {
    color: #08674a;
  }
`

StyledCard.displayName = 'StyledCard'
StyledCardError.displayName = 'StyledCardError'
StyledSectionHeader.displayName = 'StyledSectionHeader'
StyledSectionTitle.displayName = 'StyledSectionTitle'
StyledClientPortalSection.displayName = 'StyledClientPortalSection'
StyledPersonalInfoNameBox.displayName = 'StyledPersonalInfoNameBox'
StyledItemBox.displayName = 'StyledItemBox'
StyledName.displayName = 'StyledName'
StyledNameValue.displayName = 'StyledNameValue'
StyledPersonalInfoDataWrapper.displayName = 'StyledPersonalInfoDataWrapper'
StyledContactLoginDataWrapper.displayName = 'StyledContactLoginDataWrapper'
StyledPersonalInfoDataBox.displayName = 'StyledPersonalInfoDataBox'
StyledInfoDataName.displayName = 'StyledInfoDataName'
StyledInfoDataValue.displayName = 'StyledInfoDataValue'
StyledContactConsentWrapper.displayName = 'StyledContactConsentWrapper'
StyledConsentTitle.displayName = 'StyledConsentTitle'
StyledConsentDescription.displayName = 'StyledConsentDescription'
