import { CSSProperties } from '@material-ui/core/styles/withStyles'
import { StyledContainer } from 'Components/LocalResources/LocationsList/style'
import theme from 'Shared/Theme/ssTheme'
import { media } from 'styled-bootstrap-grid'
import styled from 'styled-components'

const headerHeight = `${theme.layout.headerHeight}`

interface StyledTabletAndDesktopLeftColumnI extends CSSProperties {
  zIndex?: number
  top?: string
  minHeight?: string
  showLeftColumn: boolean
}

export const StyledTabletAndDesktopLeftColumn = styled.aside<StyledTabletAndDesktopLeftColumnI>`
  width: ${theme.layout.asideWidth};
  z-index: ${(props) => (props.zIndex ? props.zIndex : 1)};
  position: absolute;
  top: ${(props) => (props.top ? props.top : '0')};
  background-color: #fff;
  overflow: hidden;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
  box-shadow: ${theme.layout.asideShadow};
  min-height: 100%;
  height: ${(props) => props.minHeight || `calc(100vh - ${headerHeight})`};

  &::-webkit-scrollbar {
    display: none;
  }

  left: ${(props) =>
    props.showLeftColumn ? '0' : `-${theme.layout.asideWidth}`};
  transition: left 0.5s ease-in-out;

  @media only screen and (max-width: 1024px) {
    width: 375px;
    height: 100%;
  }
  @media (max-width: 574px) {
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
  }
`

export const StyledTabletContainer = styled(StyledContainer)`
  position: relative;
  padding-top: 0;
  padding-bottom: 0;
  height: 100%;

  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
  @media (min-width: 481px) and (max-width: 1024px) {
    .map-search-bar  {
    margin: 0;
    padding: 15px;
    > div:first-of-type{
        button{
        height: 100%;
        top: 0;
        right: 0;
        width:4rem;
        &:before{
          display: none;
      }
     }
    }
   }
    .map-search-box {
      flex: 1 1 auto;
      > div:first-of-type{
       margin:0 15px 0 0;
       }
      input{
        font-size: 0.875rem;
        height: 50px;
        padding: 1rem;
        border-radius: 50px;
      }
      + button{
      background-color:#F9FBFC;
      height:auto !important;
      padding: 12px;
      svg{
      fill: rgb(42, 42, 42) !important;
      }
      }
    }
 
`

export const StyledLocationListContainer = styled.div<CSSProperties>`
  height: ${(props) =>
    props.height || `calc(100vh - ${headerHeight} - 6.7rem)`};
  overflow-y: auto;
  ${media.tablet`
    padding-bottom: 5rem;
    padding-bottom: 0rem;
  `}
  @media (min-width:481px) and (max-width:1024px) {
    height: calc(100% - 105px); !important;
    padding-bottom: 20px;
  }
  @media (min-width:481px) and (max-width:767px) {
        height: calc(100% - 105px); !important;
  } 
  ${media.desktop`
    padding-bottom: 0;
    padding-top: 0;
  `}
  scrollbar-width: thin;
  scrollbar-color: #c8c8c8 #f5f5f5;
  &::-webkit-scrollbar {
    display: block;
    background-color: #fff;
    width: 0.4rem;
    height: 0.3rem;
    border: 1px solid #ececec;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border-radius: 1.6rem;
    height: 7rem;
  }
`

export const StyledMapSearchButton = styled.button`
  width: 200px;
  height: 3rem;
  display: flex;
  align-items: baseline;
  position: relative;
  left: 65.5%;
  background-color: #fff;
  font-weight: 500;
  font-size: 14px;
  color: #08674a;
  border: 2px solid #11ab7c;
  border-radius: 50px;
  padding: 14px 18px;
  margin-top: 24px;
  cursor: pointer;
  box-shadow: 5px 5px 20px 0px #aeaeae40;

  @media only screen and (max-width: 1025px) {
    align-items: center;
  }

  @media only screen and (max-width: 480px) {
    width: 170px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    font-size: 13px;
  }
`
StyledLocationListContainer.displayName = 'StyledLocationListContainer'
StyledTabletAndDesktopLeftColumn.displayName =
  'StyledTabletAndDesktopLeftColumn'
StyledTabletContainer.displayName = 'StyledTabletContainer'
StyledMapSearchButton.displayName = 'StyledMapSearchButton'
