import React from 'react'
import { Hour } from 'Components/LocalResources/FindHelpResource'
import { TodaysClosingTimeFunction } from 'Pages/LocationDetail/NextStepsComponent/timeUtils'
import { timeIconPath } from 'Shared/Icons/LocationInfo/paths'

import LocationInfo from '../LocationInfo/LocationInfo'

interface TodaysClosingTimeProps {
  businessHours: Hour[]
  alignitems?: string
  timezoneName: string
}

const TodaysClosingTime = ({
  businessHours,
  alignitems,
  timezoneName,
}: TodaysClosingTimeProps) => {
  const { isOpenToday, closingTime, nextOpenedDayInfo } =
    TodaysClosingTimeFunction(businessHours, timezoneName)
  const capitalizeFirstLetter = (str: string) =>
    str.charAt(0).toUpperCase() + str.slice(1)
  const closingTimeText = `Open until ${closingTime} Today`
  const openingTimeText =
    nextOpenedDayInfo != null
      ? `Opens ${capitalizeFirstLetter(nextOpenedDayInfo.day)} at ${nextOpenedDayInfo?.openingTime}`
      : ''

  const LocationInfoTimeContent = isOpenToday
    ? closingTimeText
    : openingTimeText

  return (
    <div style={{ marginTop: '0.875rem' }}>
      <LocationInfo
        alignitems={alignitems}
        content={LocationInfoTimeContent}
        screenReaderLabel={'Open or Close'}
        icon={timeIconPath}
      />
    </div>
  )
}

export default TodaysClosingTime
