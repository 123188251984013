import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { createSelector } from '@reduxjs/toolkit'
import * as Sentry from '@sentry/browser'
import { clientAuthenticated, createClientScreening } from 'Actions/client'
import { verificationSkipped } from 'Actions/preScreener'
import useOtp from 'Components/Auth/Hooks/useOtp'
import useAcceptTerms from 'Components/Client/termsHooks/useAcceptTerms'
import {
  parseEmailOrPhone,
  parseScreeningData,
} from 'Components/PreScreener/helpers'
import { getSentryUserFromOtp } from 'Pages/helpers'
import { toggleShowLoginLink } from 'Reducers/uiSlice'
import { SubmissionError } from 'redux-form'
import {
  EMAIL_ALREADY_TAKEN_ERROR,
  ERROR_GENERIC_MESSAGE,
} from 'Shared/constants'
import { getScreeningType } from 'Shared/helpers'

import useStep from './useStep'

const useAuthenticationPath = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const {
    handleSkipClientOtp,
    handleRequestOtp,
    handleUpdateClient,
    handleVerifyOtp,
    setClientId,
    setClientCredentials,
    parseIdFromClient,
    parseIdFromUserable,
  } = useOtp()

  const { handleAcceptTerms } = useAcceptTerms()

  const { handleUpdateStep, step } = useStep()

  const selectFromState = createSelector(
    [
      (state) => state.screener,
      (state) => state.preScreener,
      (state) => state.client,
    ],
    (screener, preScreener, client) => {
      const {
        organization,
        location,
        hasCustomQuestions: customQuestionsFlag,
      } = screener
      return {
        client,
        preScreener,
        orgAndLoc: {
          organization,
          location,
        },
        customQuestionsFlag,
      }
    }
  )

  const applicationState = useSelector((state) => selectFromState(state))

  const getPhoneOrEmailFromState = (state) => ({
    email: state.client.email,
    primaryPhone: state.client.primaryPhone,
  })

  const handleAuthenticateAndContinue = ({ formData, isACaseManager }) => {
    const { otp } = formData
    const { email, primaryPhone } = formData.emailOrPhone
      ? parseEmailOrPhone(formData)
      : getPhoneOrEmailFromState(applicationState)

    return handleVerifyOtp({
      email,
      otp,
      primaryPhone,
      isACaseManager,
    })
      .then(handleAuthenticationResponse(isACaseManager))
      .then((response) => {
        const getId = isACaseManager ? parseIdFromClient : parseIdFromUserable
        return handleAcceptTerms(getId(response))
      })
      .then((response) => response)
  }

  const handleCreateClient = ({ formData, assistedScreening = false }) => {
    const { orgAndLoc } = applicationState
    const screeningData = parseScreeningData({
      formData,
      orgAndLoc,
      assistedScreening,
    })
    return dispatch(createClientScreening(screeningData))
      .then(() => handleRequestOtp(parseEmailOrPhone(formData)))
      .then(({ graphQLErrors }) => {
        if (!graphQLErrors) {
          nextStep()
        } else {
          throw new SubmissionError({ emailOrPhone: ERROR_GENERIC_MESSAGE })
        }
      })
      .catch(({ response: { status } }) => {
        const userIdTaken = status === 422
        const errorMessage = userIdTaken
          ? EMAIL_ALREADY_TAKEN_ERROR
          : ERROR_GENERIC_MESSAGE
        if (userIdTaken) {
          dispatch(toggleShowLoginLink())
        }
        throw new SubmissionError({ emailOrPhone: errorMessage })
      })
  }

  const handleCreateClientAndSkipOtp = ({
    formData,
    assistedScreening = false,
  }) => {
    const { orgAndLoc } = applicationState
    const screeningData = parseScreeningData({
      formData,
      orgAndLoc,
      assistedScreening,
    })
    return dispatch(createClientScreening(screeningData))
      .then(({ data }) => {
        const { id, clientLocationId } = data
        sessionStorage.setItem('clientId', id)
        sessionStorage.setItem('clientLocationId', clientLocationId)
        handleUpdateClient(id)
        return handleAcceptTerms(id)
      })
      .then(() => {
        dispatch(verificationSkipped())
        handleUpdateStep(6)
      })
      .catch(({ response: { status } }) => {
        const userIdTaken = status === 422
        const errorMessage = userIdTaken
          ? EMAIL_ALREADY_TAKEN_ERROR
          : ERROR_GENERIC_MESSAGE
        if (userIdTaken) {
          dispatch(toggleShowLoginLink())
        }
        throw new SubmissionError({ emailOrPhone: errorMessage })
      })
  }

  const handleSkipAuthenticateAndContinue = ({ email, primaryPhone }) => {
    handleSkipClientOtp({ email, primaryPhone })
      .then(handleSkipAuthResponse)
      .then((response) => {
        return handleAcceptTerms(parseIdFromClient(response))
      })
      .then((response) => response)
  }

  const handleAuthenticationResponse = (isACaseManager) =>
    handleMutationResponse({
      errorObject: { otp: 'Invalid credentials' },
      operationName: isACaseManager
        ? 'caseManagerVerifyClientOtp'
        : 'userVerifyOtp',
      isACaseManager,
    })

  const handleSkipAuthResponse = handleMutationResponse({
    errorObject: { otp: ERROR_GENERIC_MESSAGE },
    operationName: 'caseManagerSkipClientOtp',
    isACaseManager: false,
  })

  function handleMutationResponse({
    operationName,
    errorObject,
    isACaseManager,
  }) {
    return ({ graphQLErrors, data }) => {
      if (graphQLErrors) {
        throw new SubmissionError(errorObject)
      } else {
        setClientId({ data, operationName, isACaseManager })
        let user
        if (!isACaseManager) {
          user = getSentryUserFromOtp(data)
          setClientCredentials({ operationName, data })
          dispatch(clientAuthenticated(true))
        } else {
          user = {
            ...JSON.parse(localStorage.getItem('user')),
            screening_type: getScreeningType(),
          }
        }
        Sentry.setUser(user)
        localStorage.setItem('user', JSON.stringify(user))
        nextStep()
        return { data, operationName }
      }
    }
  }

  //ToDo: refactor so that step does not come from closure
  const nextStep = () => {
    const { customQuestionsFlag } = applicationState
    if (step === 7) {
      if (!window.localStorage.getItem('noStateBenefitWarning')) {
        if (customQuestionsFlag) {
          navigate('/questions')
        } else {
          navigate('/screening')
        }
      }
    } else {
      // need to do something here to send the case manager to the OTP page
      handleUpdateStep(step + 1)
    }
  }

  return {
    handleAuthenticateAndContinue,
    handleCreateClient,
    handleCreateClientAndSkipOtp,
    handleSkipAuthenticateAndContinue,
    nextStep,
  }
}

export default useAuthenticationPath
